* {
  box-sizing: border-box;
}
body.dark-theme {
  background: #272829;
  .Portfolio {
    color: #cacaca;
    background: #272829;
    .header {
      background: #272829;
    }
    h2,
    h3 {
      color: #ffffff;
    }
    .info-border {
      background: #ffffff;
    }
    .icon {
      color: #ffffff;
    }
    .divider {
      background: #ffffff;
    }
    .navmenu a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.Portfolio {
  color: #707070;
  .header {
    background: #ffffff;
  }
  h2,
  h3 {
    color: #000000;
  }
  .info-border {
    background: #000000;
  }
  .divider {
    background: #000000;
  }
}

.accentbar {
  width: 100%;
  height: 10px;
  background: linear-gradient(45deg, #b1ffbb, #99d7dc);
}

.header {
  width: 100%;
}

.container {
  width: 80%;
  margin: 0 auto;
}

h2,
h3 {
  margin-bottom: 0px;
}

.Portfolio.fixed {
  .header {
    position: fixed;
    z-index: 100;
    .info-border {
      width: 100%;
      margin-left: 0px;
    }
  }

  .header-placeholder {
    width: 100%;
    height: 160px;
  }
}

.header-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.jobtitle {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-info {
  width: 185px;
}

.info-border {
  height: 2px;
  width: 185px;
  background: #000000;
  transition: width 200ms ease, margin 200ms ease;
  margin-left: 10%;
}

.header-social {
  .icon {
    font-size: 25px;
    color: #000000;
    margin-right: 5px;
  }
  display: flex;
  font-size: 14px;
}

.linkedin {
  margin-left: 10px;
}

.navmenu {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 185px;
  align-items: center;
  span {
    height: 50px;
  }
  a {
    cursor: pointer;
  }
}

p {
  line-height: 20px;
}

.ahref {
  background: linear-gradient(45deg, #63b970, #4aa0a7);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.devlabels {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.divider {
  width: 100%;
  height: 5px;
  margin-top: 20px;
}

.devlabel {
  float: left;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding: 3px;
  font-size: 14px;
  margin-right: 5px;
  margin-top: 6px;
  cursor: default;
  user-select: none;
}

.devlabel {
  &.react {
    color: #61dafb;
    border-color: #61dafb;
  }
  &.php {
    color: #666699;
    border-color: #666699;
  }
  &.html5 {
    color: #d15b2a;
    border-color: #d15b2a;
  }
  &.css3 {
    color: #285ea7;
    border-color: #285ea7;
  }
  &.javascript {
    color: #f0db4e;
    border-color: #f0db4e;
  }
  &.node\.js {
    color: #43853d;
    border-color: #43853d;
  }
  &.go {
    color: #00a29c;
    border-color: #00a29c;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
}

.project {
  width: 300px;
  height: 150px;
  position: relative;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px;
  border: 5px solid;
  cursor: default;
  user-select: none;
  border-image-source: linear-gradient(45deg, #63b970, #4aa0a7);
  border-image-slice: 1;
  background: linear-gradient(
    45deg,
    rgba(99, 185, 112, 0.1),
    rgba(74, 160, 167, 0.1)
  );
  opacity: 0.8;
  transition: opacity 1s ease;
  &:hover {
    border-image-source: linear-gradient(-45deg, #63b970, #4aa0a7);
    opacity: 1;
  }
  .name {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .desc {
    font-size: 11px;
    line-height: 13px;
  }
  .img-cont {
    width: 60%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 2px solid white;
    transition: width 0.2s ease;
  }
  .desc-cont {
    width: 40%;
    position: absolute;
    z-index: 2;
    color: #fff;
    top: 0;
    left: 0;
    padding: 3px;
    transition: width 0.2s ease;
  }
  .desc-bg {
    width: 40%;
    position: absolute;
    height: 100%;
    background: linear-gradient(-45deg, #63b970, #4aa0a7);
    opacity: 0.3;
    top: 0;
    left: 0;
    z-index: 2;
    transition: width 0.2s ease;
  }
}

.footer {
  height: 50px;
  width: 100%;
}

@media all and (max-width: 480px) {
  .header-container {
    flex-flow: column;
  }
  .navmenu {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  .info-border {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .header-info {
    font-size: 70%;
  }
  .header-social {
    .icon {
      font-size: 20px;
    }
    font-size: 12px;
  }
  .jobtitle {
    font-size: 12px;
  }

  .project {
    .desc-cont,
    .desc-bg {
      width: 50%;
    }
    .img-cont {
      width: 50%;
    }
  }
}
